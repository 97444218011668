<template>
  <div class="tabs">
    <div
      :class="['item', { active: active == 'Schedule' }]"
      @click="$router.push({ name: 'Schedule' })"
    >
      <img :src="require(`@/assets/imgs/comm/Schedule.svg`)" alt="" />
      {{ $t("home.Schedule") }}
    </div>
    <div
      :class="['item', { active: active == 'Integral' }]"
      @click="$router.push({ name: 'Integral' })"
    >
      <img :src="require(`@/assets/imgs/comm/Integral.svg`)" alt="" />
      {{ $t
      ("home.Integral") }}
    </div>
    <div
      :class="['item', { active: active == 'Player' }]"
      @click="$router.push({ name: 'Player' })"
    >
      <img :src="require(`@/assets/imgs/comm/Player.svg`)" alt="" />
      {{ $t
      ("home.Player") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "tabs",
  computed: {
    active() {
      return this.$route.name;
    },
    theme() {
      return this.common.theme;
    },
    ...mapState(["common"]),
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-left: 70px;
    img {
      margin-right: 8px;
    }
  }
  .active {
    background: url(../../assets/imgs/comm/tab-active-bg.svg) no-repeat;
        background-position: center bottom;
    background-size: 100% 5px;
  }
  .schedule.active {
    background: none;
  }
  .scheduleBg {
    position: absolute;
    width: 150px;
    top: -18px;
  }
  .wrap {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
  }
}
</style>