<template>
  <div class="Home">
    <Header />
    <div class="viewContent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "Home",
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.viewContent {
  height: 684px;
  margin-top: -148px;
}
.Home{
  min-height: 100%;
}
</style>